import * as ActionTypes from '../constants/ActionTypes';


const fetchLoginFailAction = (error) => {
  return {
    type: ActionTypes.FETCH_LOGIN_FAIL,
    error
  };
};

const fetchLoginSuccessAction = (json) => {
  window.location.href = json.redirect;
  return {
    type: ActionTypes.FETCH_LOGIN_SUCCESS,
  };
};


const fetchLoginAction = () => {
  return {
    type: ActionTypes.FETCH_LOGIN,
  };
};


export const fetchLogin = (username, password) => {// PROVISORIO..
  return dispatch => {
    dispatch(fetchLoginAction());
    const opts = {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        username,
        password
      }),
    };
    let url = '/authenticateUser';
    return fetch(localUrl + url, opts)
      .then(response => response.json())
      .then(json => dispatch(fetchLoginSuccessAction(json)))
      .catch(error => dispatch(fetchLoginFailAction(error)));
  };
};