import React from 'react';

const LoaderComponent = ({message}) => {
  return (
    <div className='loader-container'>
      <div className="spinner"></div>
      <span>{message ? message : "Loading..."}</span>
    </div>
  );
};

export default LoaderComponent;
