import * as ActionTypes from '../constants/ActionTypes';

const defaultState = {
  loading: false,
  error: false,
};

export default function(state = defaultState, action) {
	switch (action.type) {
    case ActionTypes.FETCH_LOGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ActionTypes.FETCH_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.FETCH_LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
		default:
			return state;
	}
}