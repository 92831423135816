import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as LoginActions from './../actions/LoginActions';
import LoaderComponent from './LoaderComponent';


const Login = ({ dispatch, loading, error }) => {
  console.log(loading, error)
  const clickLogin = () => {
    dispatch(LoginActions.fetchLogin($('#usr').val(), $('#pwd').val()));//mejorar
  };
  return (
    <div>
      <div className="container">
        <div className="logo">
          <h1><a href="#"><img src="/web/statics/images/eyecue.svg" alt="" /><span>EIKYOU</span></a></h1>
        </div>
      </div>
      <div className="login-container container">
        <div className="row">
          <div className="col-md-4 col-md-offset-4">
            
            <div className="form-group">
              <label>Username:</label>
              <input type="text" className="form-control" id="usr" />
            </div>
            <div className="form-group">
              <label >Password:</label>
              <input type="password" className="form-control" id="pwd" />
            </div>
            <div className="form-group text-right">
            <button className="btn" onClick={() => clickLogin()} > LOGIN </button>
            </div>
            { error &&
              <div className="alert-eye alert-error">
                <p><strong>ERROR:</strong> We are having troubles to login you to Eyecue. Please verify your <b>username</b> and <b>password</b> are right.</p>
              </div>
            }
          </div>
        </div>
      </div>
      { loading &&
        <LoaderComponent  message='Loading...'  />
      }
    </div>
    
  );
};

Login.propTypes = {
};

export default connect(state => state.Login)(Login);
