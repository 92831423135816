import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Login from './container/Login';
import store from './store/Store';

if (typeof document !== 'undefined'){
  ReactDOM.render(<Provider store={store}>
    <Login />
  </Provider>, document.getElementById('main-container'));
}